import { SoftwrkApi } from "configs/StoreQueryConfig";

const BASE_URL = "/requisitionmanager/api/Auth";
// requisitionmanager/api/Auth/authorization?urltoken=4fcb4f0a4f498cdc4aef10c4576b94379da176234ab58cdd831e57adf0a1

console.log(BASE_URL);

export const SignupApi = SoftwrkApi.injectEndpoints({
  endpoints: (builder) => ({
    // login: builder.mutation({
    //   query: (config) => ({
    //     url: `${BASE_URL}/authorization?urltoken=${config?.urltoken}`,
    //     method: "POST",
    //     // param:
    //     // ...config,
    //   }),
    // }),
    login: builder.mutation({
      query: (config) => {
        const { urltoken } = config.data;
        // console.log(urltoken);
        return {
          url: `${BASE_URL}/authorization`,
          method: "POST",
          params: { urltoken },
        };
      },
    }),
  }),
});

export default SignupApi;
